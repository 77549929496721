export default {
  common: {
    play: "PLAY",
    songs: "Songs",
  },
  nav: {
    home: "Home",
    explore: "Explore",
    library: "Library",
    search: "Search",
    github: "GitHub Repo",
  },
  footer: {
    settings: "Settings",
  },
  home: {
    recommendPlaylist: "Recommended Playlists",
    recommendArtist: "Recommended Artists",
    newAlbum: "Latest Albums",
    seeMore: "SEE MORE",
    charts: "Charts",
  },
  library: {
    sLibrary: "'s Library",
    likedSongs: "Liked Songs",
    sLikedSongs: "'s Liked Songs",
    playlists: "Playlists",
    albums: "Albums",
    artists: "Artists",
    mvs: "MVs",
    newPlayList: "New Playlist",
    userProfileMenu: {
      settings: "Settings",
      logout: "Logout",
    },
  },
  explore: {
    explore: "Explore",
    loadMore: "Load More",
  },
  artist: {
    latestRelease: "Latest Releases",
    popularSongs: "Popular Songs",
    showMore: "SHOW MORE",
    showLess: "SHOW LESS",
    EPsSingles: "EPs & Singles",
    albums: "Albums",
    withAlbums: "Albums",
    artist: "Artist",
    videos: "Music Videos",
    following: "Following",
    follow: "Follow",
  },
  album: {
    released: "Released",
  },
  playlist: {
    playlist: "Playlists",
    updatedAt: "Updated at",
    search: "Search in playlist",
  },
  login: {
    accessToAll: "Access to all data",
    loginText: "Login to Netease",
    search: "Search account",
    readonly: "Only access to public data",
    usernameLogin: "Username Login",
    searchHolder: "Your account username",
    enterTip: "Press 'enter' to search",
    choose: "Choose your account",
    confirm: "Confirm",
    countryCode: "Country code",
    phone: "Phone",
    email: "Email address",
    password: "Password",
    login: "Login",
    loginWithEmail: "Login with Email",
    loginWithPhone: "Login with Phone",
    notice: `YesPlayMusic promises not to save any of your account information to the cloud.<br />
      Your password will be MD5 encrypted locally and then transmitted to NetEase Music API.<br />
      YesPlayMusic is not the official website of NetEase Music, please consider carefully before entering account information. You can also go to <a href="https://github.com/qier222/YesPlayMusic">YesPlayMusic's GitHub repository</a> to build and use the self-hosted NetEase Music API.`,
    noticeElectron: `Your password will be MD5 encrypted locally and then transmitted to NetEase Music API.<br />
      YesPlayMusic promises not to save any of your account information to the cloud.<br />`,
  },
  mv: {
    moreVideo: "More Videos",
  },
  next: {
    nowPlaying: "Now Playing",
    nextUp: "Next Up",
  },
  player: {
    like: "Like",
    previous: "Previous Song",
    next: "Next Song",
    repeat: "Repeat",
    repeatTrack: "Repeat Track",
    shuffle: "Shuffle",
    play: "Play",
    pause: "Pause",
    mute: "Mute",
    nextUp: "Next Up",
  },
  modal: {
    close: "Close",
  },
  search: {
    artist: "Artists",
    album: "Albums",
    song: "Songs",
    mv: "Music Videos",
    playlist: "Playlists",
    noResult: "No Results",
    searchFor: "Search for",
  },
  settings: {
    settings: "Settings",
    logout: "LOGOUT",
    language: "Languages",
    musicQuality: {
      text: "Music Quality",
      low: "Low",
      medium: "Medium",
      high: "High",
      lossless: "Lossless",
    },
    cacheLimit: {
      text: "Songs Cache limit",
      none: "None",
    },
    lyricFontSize: {
      text: "Lyric Font Size",
      small: "Small",
      medium: "Medium",
      large: "Large(Default)",
      xlarge: "X-Large",
    },
    deviceSelector: "Audio Output Device",
    permissionRequired: "Microphone Permission Required",
    appearance: {
      text: "Appearance",
      auto: "Auto",
      light: "Light",
      dark: "Dark",
    },
    automaticallyCacheSongs: "Automatically cache songs",
    clearSongsCache: "Clear Songs Cache",
    cacheCount: "Cached {song} songs ({size})",
    showLyricsTranslation: "Show lyrics translation",
    showLyricsDynamicBackground: "Show lyrics dynamic background",
    minimizeToTray: "Minimize to tray",
    showGitHubIcon: "Show GitHub icon",
    showUnavailableSongInGreyStyle: "Show unavailable song in grey style",
    showPlaylistsByAppleMusic: "Show playlists by Apple Music",
    enableDiscordRichPresence: "Enable Discord Rich Presence",
    enableGlobalShortcut: "Enable Global Shortcut",
    showLibraryDefault: "Show library default",
  },
  contextMenu: {
    play: "Play",
    playNext: "Play Next",
    saveToMyLikedSongs: "Save to my Liked Songs",
    removeFromMyLikedSongs: "Remove from my Liked Songs",
  },
  toast: {
    savedToMyLikedSongs: "Saved to my Liked Songs",
    removedFromMyLikedSongs: "Removed from my Liked Songs",
  },
};
